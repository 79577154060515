import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/led-street-light/6(1).jpg"
import img2 from "../../images/led-street-light/6(4).jpg"
import img3 from "../../images/led-street-light/6(5).jpg"
import img4 from "../../images/led-street-light/6(6).jpg"

import descImg1 from "../../images/led-street-light/applications.png"
import ledSttreetLightSpecSheet from "../../images/led-street-light/specSheet.svg"

const LEDStreetLightPage = () => {
  return (
    <Layout home={false}>
      <SEO title="LED street Light" />
      <ProductPage
        productTitle="LED street Light"
        showcase={
          <>
            <p style={{ lineHeight: "1.7rem" }}>
              High Street Lighting LED street lighting is a cost-effective and
              sustainable choice for cities of today and future. HSL LED street
              lighting systems are smart and versatile, so you can manage,
              maintain, and monitor the entire system simply and
              efficiently.This product adopts the high power LED as the light
              source, using dozens of high power Nichia emitters of 5 watt LED
              with the words leading optical allocation, advanced thermal,
              structural, and circuit design, it is a highly cost-effective.
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img1, img2, img3, img4]}
        description={
          <div>
            <div style={{ width: "65%" }}>
              <ul>
                <li style={{ fontSize: "1.2rem" }}>
                  Ranges from 28W up to 110W LED Street Light
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  Greater than 90 lumen/Watt efficacy
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  Type II optics- PC Optical Enclosure
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  Zero Uplight – 100% Usable Light
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  10KV/10KA Surge Protection (Optional)
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  CISPR 22 Class B Complaint
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  ZERO-90 degree Mounting Tilt Mechanism
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  The Optic lenses give a wider dispersion of light, covering
                  maximum area and increase the pole spacing{" "}
                </li>
                <li style={{ fontSize: "1.2rem" }}>IP-67 Driver</li>
                <li style={{ fontSize: "1.2rem" }}>
                  ISO 9001:2008 (Manufacturing)
                </li>
                <li style={{ fontSize: "1.2rem" }}>
                  More Energy Efficient and Environment Friendly
                </li>
                <li style={{ fontSize: "1.2rem" }}>No Glass- Reduce Damage </li>
                <li style={{ fontSize: "1.2rem" }}>No INSECT SWARMING</li>
                <li style={{ fontSize: "1.2rem" }}>
                  Designed and Manufacturing in INDIA
                </li>
              </ul>
            </div>
            <div className="product-description-heading">Applications</div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <img
                src={descImg1}
                style={{
                  maxWidth: "90%",
                  margin: "0 auto",
                  maxHeight: "20rem",
                }}
              />
            </div>
          </div>
        }
        technicalDetails={
          <div style={{ width: "180%", marginLeft: "-40%" }}>
            <img src={ledSttreetLightSpecSheet} style={{ width: "100%" }} />
          </div>
        }
      />
    </Layout>
  )
}

export default LEDStreetLightPage
